<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

defineProps<{ title?: string }>()

const showNotification = ref(false)

const flash = useFlash()
watchEffect(() => {
  if (flash.value?.banner) {
    showNotification.value = true
  }
})
</script>

<template>
  <Head v-if="title !== undefined" :title="title" />

  <div
    class="flex min-h-[100dvh] md:min-h-[50dvh] flex-col sm:pt-12 sm:px-6 lg:px-8 px-4 pt-2 bg-secondary"
    v-bind="$attrs"
  >
    <AppLogo class="mx-auto h-8 w-auto pt-8" />

    <div class="sm:mx-auto sm:w-full sm:max-w-md mt-24">
      <slot />
    </div>

    <TuiNotificationHost alignment="top-right" />

    <TuiModalFunctionsHost />

    <TuiFlashNotification
      v-if="flash"
      v-model:show="showNotification"
      :title="flash.banner"
      :type="flash.bannerStyle"
    />
  </div>
</template>
