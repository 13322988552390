<template>
  <div>
    <svg
      class="w-auto"
      width="215"
      height="40"
      viewBox="0 0 215 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.407 33.1746L23.0684 30.3704C20.8815 32.9806 18.3066 34.2857 15.3437 34.2857C12.6629 34.2857 10.3878 33.351 8.51839 31.4815C6.64893 29.612 5.7142 27.3369 5.7142 24.6561C5.7142 21.9753 6.64893 19.7002 8.51839 17.8307C10.3878 15.9612 12.6629 15.0265 15.3437 15.0265C18.3419 15.0265 20.9168 16.3139 23.0684 18.8889L27.8832 15.7672C26.3312 13.7213 24.4441 12.134 22.2219 11.0053C20.035 9.84127 17.7422 9.25926 15.3437 9.25926C11.1462 9.25926 7.54839 10.776 4.5502 13.8095C1.51673 16.843 0 20.4586 0 24.6561C0 28.8536 1.51673 32.4515 4.5502 35.4497C7.54839 38.4832 11.1462 40 15.3437 40C17.7422 40 20.0173 39.4709 22.169 38.4127C24.3206 37.3192 26.1195 35.8201 27.5657 33.9153L27.407 33.1746Z"
      />

      <path d="M39.7915 39.1005V0.952381H34.0773V39.1005H39.7915Z" />

      <path
        d="M52.3988 39.1005V10.2116H46.6846V39.1005H52.3988ZM53.3511 3.80953C53.3511 2.75132 52.9808 1.85185 52.24 1.11111C51.4993 0.370371 50.5998 0 49.5417 0C48.4835 0 47.584 0.370371 46.8433 1.11111C46.1026 1.85185 45.7322 2.75132 45.7322 3.80953C45.7322 4.86772 46.1026 5.7672 46.8433 6.50794C47.584 7.24868 48.4835 7.61905 49.5417 7.61905C50.5998 7.61905 51.4993 7.24868 52.24 6.50794C52.9808 5.7672 53.3511 4.86772 53.3511 3.80953Z"
      />

      <path
        d="M85.5348 33.1746L81.1963 30.3704C79.0094 32.9806 76.4344 34.2857 73.4715 34.2857C70.7908 34.2857 68.5157 33.351 66.6462 31.4815C64.7768 29.612 63.842 27.3369 63.842 24.6561C63.842 21.9753 64.7768 19.7002 66.6462 17.8307C68.5157 15.9612 70.7908 15.0265 73.4715 15.0265C76.4697 15.0265 79.0446 16.3139 81.1963 18.8889L86.011 15.7672C84.459 13.7213 82.5719 12.134 80.3497 11.0053C78.1628 9.84127 75.8701 9.25926 73.4715 9.25926C69.274 9.25926 65.6762 10.776 62.678 13.8095C59.6446 16.843 58.1278 20.4586 58.1278 24.6561C58.1278 28.8536 59.6446 32.4515 62.678 35.4497C65.6762 38.4832 69.274 40 73.4715 40C75.8701 40 78.1452 39.4709 80.2968 38.4127C82.4484 37.3192 84.2474 35.8201 85.6936 33.9153L85.5348 33.1746Z"
      />

      <path
        d="M117.813 39.1005L106.596 21.6931L118.131 10.2116H110.088L97.9193 22.381V0.952381H92.2051V39.1005H97.9193V30.3704L102.364 25.9259L110.988 39.1005H117.813Z"
      />

      <path
        d="M152.914 24.6561C152.914 20.5291 151.538 16.9489 148.787 13.9153C146 10.8113 142.561 9.25926 138.469 9.25926C136.282 9.25926 134.325 9.68254 132.596 10.5291C130.868 11.3404 129.298 12.5397 127.887 14.127V0.952381H122.173V39.1005H127.887V35.1323C129.298 36.7548 130.868 37.9718 132.596 38.7831C134.325 39.5944 136.282 40 138.469 40C142.596 40 146.053 38.448 148.839 35.3439C151.555 32.3104 152.914 28.7478 152.914 24.6561ZM147.146 24.6561C147.146 27.3369 146.212 29.612 144.342 31.4815C142.473 33.351 140.198 34.2857 137.517 34.2857C134.836 34.2857 132.561 33.351 130.692 31.4815C128.822 29.612 127.887 27.3369 127.887 24.6561C127.887 21.9753 128.822 19.7002 130.692 17.8307C132.561 15.9612 134.836 15.0265 137.517 15.0265C140.198 15.0265 142.473 15.9612 144.342 17.8307C146.212 19.7002 147.146 21.9753 147.146 24.6561Z"
      />

      <path
        d="M188.228 39.1005V10.2116H182.461V14.127C179.639 10.8818 176.129 9.25926 171.932 9.25926C169.886 9.25926 167.981 9.64727 166.218 10.4233C164.489 11.164 162.955 12.328 161.615 13.9153C158.863 16.8783 157.488 20.4586 157.488 24.6561C157.488 28.8183 158.846 32.381 161.562 35.3439C164.313 38.448 167.77 40 171.932 40C176.2 40 179.71 38.3774 182.461 35.1323V39.1005H188.228ZM182.461 24.6561C182.461 27.3369 181.526 29.612 179.657 31.4815C177.787 33.351 175.512 34.2857 172.831 34.2857C170.151 34.2857 167.876 33.351 166.006 31.4815C164.137 29.612 163.202 27.3369 163.202 24.6561C163.202 21.9753 164.137 19.7002 166.006 17.8307C167.876 15.9612 170.151 15.0265 172.831 15.0265C175.512 15.0265 177.787 15.9612 179.657 17.8307C181.526 19.7002 182.461 21.9753 182.461 24.6561Z"
      />

      <path
        d="M210.95 14.9735V9.4709C210.456 9.40035 210.015 9.34744 209.627 9.31217C209.239 9.2769 208.904 9.25926 208.622 9.25926C205.553 9.25926 202.961 10.3351 200.844 12.4868V10.2116H195.13V39.1005H200.844V21.8519C200.844 19.9471 201.515 18.3245 202.855 16.9841C204.16 15.679 205.783 15.0265 207.723 15.0265C208.534 15.0265 209.363 15.1675 210.209 15.4497L210.95 14.9735Z"
      />

      <path
        d="M214.783 35.291C214.783 34.2328 214.412 33.3333 213.672 32.5926C212.966 31.8519 212.067 31.4815 210.973 31.4815C209.915 31.4815 209.016 31.8519 208.275 32.5926C207.569 33.3333 207.217 34.2328 207.217 35.291C207.217 36.3492 207.569 37.2487 208.275 37.9894C209.016 38.7302 209.915 39.1005 210.973 39.1005C212.067 39.1005 212.966 38.7302 213.672 37.9894C214.412 37.2487 214.783 36.3492 214.783 35.291Z"
      />
    </svg>

    <h1 class="text-3xl font-medium text-right">quiz.</h1>
  </div>
</template>
