import '@clickbar/tailwindui-vue/style.css'
import '@fontsource-variable/inter'
import { createInertiaApp, Link } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { createApp, h } from 'vue'

import './tailwind.css'
import sentryVuePlugin from '@/app/sentry'
import AppLayoutVue from '@/layouts/AppLayout.vue'

import type { DefineComponent } from 'vue'

// Fix for iOS Safari touch to active
// eslint-disable-next-line @typescript-eslint/no-empty-function
window.document.addEventListener('touchstart', () => {})

void createInertiaApp({
  title: () => `clickbar.quiz`,
  progress: {
    color: '#4B5563',
  },

  resolve: async (name: string) => {

    const page =  await resolvePageComponent(
      `../pages/${name}.vue`,
      import.meta.glob<DefineComponent>('../pages/**/*.vue'),
      )

      const layouts = [AppLayoutVue]
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      page.default.layout = page.default.layout ?? layouts

      return page
  },

  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(sentryVuePlugin)
      .component('inertia-link', Link)
      .component('InertiaLink', Link)
      .mount(el)
  },
})
