import type { SharedProps } from '@/types'

export function useEnvironment() {
  return computed(() => usePage<SharedProps>().props.environment)
}

export function useIsEnvironment(environment: string) {
  return computed(() => useEnvironment().value === environment)
}

export function useUser() {
  return computed(() => usePage<SharedProps>().props.auth.user)
}

export function useFlash() {
  return computed(() => usePage<SharedProps>().props.flash)
}
